import { Link } from "gatsby";
import React, { useContext } from "react";
import { toTextBlock, toFirstCapital } from "../lib/helpers";
import {
  GetStarted,
  PageHeader,
  ResourcesSuggestions,
  SectionWrapper,
} from "./sections";
import PortableText from "./portableText";
import { Button } from "./button";
import { CardGrid } from "./card/card-grid";
import {
  ATTRIBUTION_PARAM,
  SIGNUP_PHRASE,
  SIGNUP_URL,
} from "./sections/snowflake/navigation";
import { CardIntegration } from "./sections/snowflake/integrations";
import { ArrowLeft } from "./icon/arrow-left";
import { SiteContext } from "./global/site-context";
import { ImageBuilder } from "./global/image-builder";

export const IntegrationPage = ({
  _id,
  company,
  mainImage,
  _rawExcerpt,
  category,
  plan,
  screenshot,
  posts = [],
  integrationsSimilar = [],
  integrationsPairs = [],
  _rawBody,
  docsUrl,
  suggestions,
}) => {
  const site = useContext(SiteContext);

  return (
    <>
      <SectionWrapper
        {...{
          noHeaders: true,
          background: "dots",
          name: "header",
          backgroundCentered: true,
          padding: "tight",
          container: "large",
        }}
      >
        <div className="my-12">
          <div className="text-secodary pb-6">
            <Button
              text="See all integrations"
              href="/integrations/"
              icon={<ArrowLeft size="12" />}
              bgColor="bg-dark-10"
            />
          </div>
          {_rawExcerpt && mainImage && (
            <PageHeader
              section={{
                header: `${category} integration`,
                _rawSummary:
                  company.indexOf("Common Room") === -1
                    ? toTextBlock(`${company} + Common Room`, "h2")
                        .concat(_rawExcerpt)
                        .concat(getStartedButtonBlock)
                    : toTextBlock(company, "h2")
                        .concat(_rawExcerpt)
                        .concat(getStartedButtonBlock),
                media: site.isNotMobile ? (
                  <LogoPartnership
                    logo={mainImage}
                    company={company}
                    className="rounded-3xl"
                  />
                ) : null,
              }}
            />
          )}
        </div>
      </SectionWrapper>

      <SectionWrapper container="large">
        <div className="md:grid md:grid-cols-2 md:gap-12">
          <div>
            {screenshot && (
              <ImageBuilder
                image={screenshot}
                width={750}
                alt="integration screenshot"
                className="mb-6"
              />
            )}
          </div>
          <div>
            <div className="flex mb-8">
              <div className="mr-12">
                <p className="text-secondary">Category</p>
                <p className="font-semibold">
                  <Link to={`/integrations/#filter`} className="text-link">
                    {category}
                  </Link>
                </p>
              </div>
              {plan && (
                <div>
                  <p className="text-secondary">Plan</p>
                  <p className="font-semibold">
                    <Link to="/pricing/" className="text-link">
                      {toFirstCapital(plan)}
                    </Link>
                  </p>
                </div>
              )}
            </div>
            {docsUrl && (
              <Button
                bgColor="bg-link"
                className="mb-12"
                text="Read the docs"
                href={docsUrl}
                attributes={{ target: "_blank" }}
              />
            )}
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </SectionWrapper>

      {integrationsSimilar.length > 0 && integrationsPairs.length > 0 && (
        <SectionWrapper padding="top" container="large">
          <div className="grid md:grid-cols-2 gap-8 lg:gap-24">
            <div>
              <h4 className="text-2xl font-semibold mb-6 tracking-tight">
                Similar integrations
              </h4>
              <div className="grid grid-cols-2 gap-4 md:gap-6">
                {integrationsSimilar.map((doc) => (
                  <CardIntegration doc={doc} key={doc._id} />
                ))}
              </div>
            </div>
            <div>
              <h4 className="text-2xl font-semibold mb-6 tracking-tight">
                Pairs well with {company}
              </h4>
              <div className="grid grid-cols-2 gap-4 md:gap-6">
                {integrationsPairs.map((doc) => (
                  <CardIntegration doc={doc} key={doc._id} />
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center bg-dark-2 rounded-xl p-10 mt-20">
            <h4 className="text-xl md:text-2xl mr-10">
              Connect to 50+ integrations built in-house
            </h4>
            <Button
              text="See all integrations"
              bgColor="bg-black"
              href="/integrations/#filter"
              icon={<ArrowLeft rotate={180} />}
              iconRight={true}
            />
          </div>
        </SectionWrapper>
      )}

      <SectionWrapper
        anchor="more"
        header={site.token("suggestions")}
        layout="centered"
        container="large"
      >
        {suggestions.length > 0 ? (
          <CardGrid
            nodes={suggestions}
            panels={true}
            hideLabels={false}
            showSubTags={true}
            showExtended={true}
            browseMoreHref="/resources/"
            browseMoreText="See all resources"
            maxColumns={3}
            attribution={{ action: "suggestion", label: "integration" }}
          />
        ) : posts.length > 0 ? (
          <CardGrid
            nodes={posts}
            hideMore={true}
            panels={true}
            maxColumns={3}
          />
        ) : (
          <ResourcesSuggestions section={{ cards: 3 }} />
        )}
      </SectionWrapper>

      <SectionWrapper padding="bottom" container="large">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};

const getStartedButtonBlock = [
  {
    _type: "block",
    style: "normal",
    children: [
      {
        _type: "span",
        marks: ["buttonLink"],
        text: SIGNUP_PHRASE,
        _key: "123abc",
      },
    ],
    markDefs: [
      {
        _key: "buttonLink",
        _type: "link",
        href: `${SIGNUP_URL}?${ATTRIBUTION_PARAM}=cta-button--integration`,
        style: "buttonBlack",
        icon: "none",
      },
    ],
  },
];

export const LogoPartnership = ({ logo, company, className, url }) => (
  <>
    {logo ? (
      <ImageBuilder
        image={logo}
        width={112}
        alt={`${company} logo`}
        className={className}
      />
    ) : (
      <ImageBuilder
        src={`https://logo.clearbit.com/${url}`}
        width={112}
        className={`bg-white ${className}`}
      />
    )}
    <ImageBuilder src="/static/img/link-horizontal.svg" className="w-12 mx-6" />
    <ImageBuilder src="/static/img/logo-square.svg" width={112} />
  </>
);
